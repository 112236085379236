body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  background-color: #0a0a0a;
  color: white;
  top: 0px !important;

  &.home {
    --main-bg-color: #6600ff;
    --secondary-bg-color: #ff0066;
    --logo-color: var(--secondary-bg-color);
    --main-color: #ffffff;
  }

  &.team {
    --main-bg-color: #666666;
    --secondary-bg-color: #000000;
    --logo-color: #ffffff;
    --main-color: #ffffff;
  }

  &.robotics {
    --main-bg-color: #003380;
    --secondary-bg-color: #00b3b3;
    --logo-color: var(--secondary-bg-color);
    --main-color: #ffffff;
  }

  &.coding {
    --main-bg-color: #006600;
    --secondary-bg-color: #00ff00;
    --logo-color: var(--secondary-bg-color);
    --main-color: #ffffff;
  }

  &.proto {
    --main-bg-color: #cc5200;
    --secondary-bg-color: #ffcc00;
    --logo-color: var(--secondary-bg-color);
    --main-color: #ffffff;
  }

  &.contact {
    --main-bg-color: #b30000;
    --secondary-bg-color: #ff6600;
    --logo-color: var(--secondary-bg-color);
    --main-color: #ffffff;
  }

  &.rec {
    --main-bg-color: #663300;
    --secondary-bg-color: #ff6600;
    --logo-color: var(--secondary-bg-color);
    --main-color: #ffffff;
  }
}

::selection {
  background-color: var(--secondary-bg-color);
}
p {
  font-size: 15px;

  a.link {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

li {
  font-size: 15px;
}
header {
  top: 0px !important;
  position: fixed;
  width: 100%;
  padding: 10px;
  z-index: 999;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  font-variant: small-caps;
  background-color: var(--main-bg-color);

  #google_translate_element {
    position: relative;
    padding: 3px;
    font-variant: normal;
  }

  a.logo {
    height: 60px;
    font-size: 30px;
    line-height: 60px;
    padding: 0 20px;
    text-align: center;
    box-sizing: border-box;
    float: left;
    font-weight: 700;
    text-decoration: none;
    font-family: "Roboto";
    letter-spacing: 4px;
    color: var(--logo-color);
    border-top: double var(--logo-color);
    border-bottom: double var(--logo-color);
    text-shadow: 5px 5px 2px black;

    &:hover {
      transition: 4s;
      transform: translateX(50px);
      opacity: 0;
      filter: blur(5px);
    }
  }
}
.goog-te-gadget {
  font-family: Roboto;
}
.goog-te-gadget-simple {
  background-color: transparent !important;
  border: 1px solid !important;
  padding: 8px !important;
  padding: 8px !important;
  border-radius: 4px !important;
}
.goog-te-menu-value {
  color: #fff !important;
}
.goog-te-gadget-icon {
  display: none !important;
}
/* ============ HIDE TOP BAR ============ */
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
nav {
  float: right;

  ul {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      list-style: none;

      a {
        display: block;
        margin: 5px 0;
        padding: 5px 20px;
        text-decoration: none;
        color: #ddd;
        font-weight: bold;
        -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);

        &.active,
        &:hover {
          background: var(--secondary-bg-color);
          color: var(--main-color);
          transition: 0.5s;
          box-shadow: 0 0 5px var(--secondary-bg-color),
            0 0 25px var(--secondary-bg-color),
            0 0 50px var(--secondary-bg-color),
            0 0 200px var(--secondary-bg-color);
        }
      }
    }
  }
}
.clearfix {
  clear: both;
}

.menu-toggle {
  display: none;
}

.row {
  padding: 3px;
  display: flex;
}

.flex-container {
  flex-direction: row;
  display: flex;
}

@for $i from 1 to 10 {
  .flex-item-#{$i * 10} {
    flex: $i * 10%;
  }
}

.main-main {
  flex: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  min-width: 0;
  background-color: var(--main-bg-color);
}
.grid-container {
  padding: 10px;
  box-sizing: border-box;
  border-radius: 4px;
  grid-gap: 5px;
  width: 100%;
  background-color: var(--main-bg-color);
}
.grid-item-header {
  border: 1px solid #00b3b3;
  padding: 20px;
  text-align: center;
  background: var(--secondary-bg-color);
  font-variant: petite-caps;
  font-weight: bold;
}
.grid-item {
  border: 2px solid var(--secondary-bg-color);
  padding: 20px;
  margin: 5px;
  text-align: center;

  ul {
    text-align: left;
  }
}
.side {
  flex: 20%;
  background-color: var(--main-bg-color);
  padding: 10px;
}
.main {
  flex: 80%;
  background-color: var(--main-bg-color);
  padding: 10px;
}
.responsive-image {
  max-width: 100%;
  height: auto;
}
.image {
  padding: 1px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #000000;
}
.hr {
  max-width: 1200px;
  border-bottom: double var(--secondary-bg-color);
}
footer {
  max-width: 1200px;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: var(--main-bg-color);

  a {
    text-decoration: none;
    color: var(--secondary-bg-color);
  }
}
.flip-card {
  background-color: transparent;
  width: 150px;
  height: 150px;
  border: 1px solid #f1f1f1;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #7bcbfc;
  color: black;
}

.flip-card-back {
  background-color: #000000;
  color: white;
  transform: rotateY(180deg);
}

.container-contact {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 3px;

  h2 {
    width: 100%;
    color: var(--secondary-bg-color);
    text-align: center;
    margin-bottom: 5px;
  }

  .row-100 {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    .col {
      position: relative;
      width: 100%;
      padding: 0 10px;
      margin: 30px 0 10px;
      transition: 0.5s;
    }

    .input-box {
      position: relative;
      width: 100%;
      height: 30px;
      color: var(--secondary-bg-color);

      input,
      &.textarea textarea {
        position: absolute;
        width: 100%;
        height: 100%;
        background: transparent;
        box-shadow: none;
        border: none;
        outline: none;
        font-size: 16px;
        padding: 0 10px;
        z-index: 1;
        color: #ffffff;
      }

      .text {
        position: absolute;
        top: 0;
        left: 0;
        line-height: 30px;
        font-size: 16px;
        padding: 0 10px;
        display: block;
        transition: 0.5s;
        pointer-events: none;
      }

      input:focus + .text,
      input:valid + .text {
        top: -35px;
        left: -10px;
      }

      .line {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: 2px;
        background: var(--secondary-bg-color);
        transition: 0.5s;
        border-radius: 2px;
        pointer-events: none;
      }

      input:focus ~ .line,
      input:valid ~ .line {
        height: 100%;
      }
    }
    .input-box.textarea {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 10px 0;

      textarea {
        height: 100%;
        resize: none;

        &:focus + .text,
        &:valid + .text {
          top: -35px;
          left: -10px;
        }
        &:focus ~ .line,
        &:valid ~ .line {
          height: 100%;
        }
      }
    }
  }
}
input[type="submit"] {
  border: none;
  padding: 7px 35px;
  cursor: pointer;
  outline: none;
  background: var(--secondary-bg-color);
  color: #ffffff;
  font-size: 18px;
  border-radius: 2px;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 2px;
  box-shadow: 0 0 5px var(--secondary-bg-color),
    0 0 25px var(--secondary-bg-color), 0 0 50px var(--secondary-bg-color),
    0 0 200px var(--secondary-bg-color);
}

.container {
  max-width: 1200px;
  margin: 80px auto 0px auto;

  &.proto {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .card {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 10px 0;

      &:nth-child(odd) {
        flex-direction: row;
      }

      .image-box {
        position: relative;
        left: 25px;
        width: 450px;
        height: 350px;
        z-index: 1;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content-box {
        position: relative;
        right: 25px;
        width: 450px;
        height: 350px;
        background: #000;
        display: flex;
        align-items: center;
        padding: 20px 60px 20px 60px;

        &::before {
          content: "";
          position: absolute;
          top: -50px;
          bottom: -50px;
          left: 0;
          right: 0;
          background: #000;
          z-index: -1;
        }

        h3 {
          font-size: 30px;
          color: #fff;
        }

        p {
          margin-top: 10px;
          color: #fff;
        }
      }
    }
  }
}
/* Code highlighting */
pre {
  tab-size: 4;
  counter-reset: line;
  overflow-x: auto;
}

code.line {
  counter-increment: line;

  &::before {
    content: counter(line);
    display: inline-block;
    width: 1.5em;
    border-right: 1px solid #ddd;
    padding: 0 0.5em;
    margin-right: 0.5em;
    color: #888;
  }
}
.keyword {
  color: papayawhip;
}
.string {
  color: brown;
}
.number {
  color: orange;
}
.comment {
  color: green;
}

.float {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--main-bg-color);
  color: var(--main-color);
}

.float:hover {
  cursor: pointer;
  background-color: var(--secondary-bg-color);
}

@media (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }

  .container.proto .card {
    flex-direction: column;
    max-width: 350px;
    margin: 25px 25px;

    .image-box {
      width: 100%;
      height: 250px;
      left: 0;
    }

    .content-box {
      width: 100%;
      height: auto;
      right: 0;
      padding: 30px;
      text-align: center;

      &::before {
        top: 0;
        bottom: 0;
      }
    }
  }

  body {
    margin: 3px;
  }
  footer {
    margin: 3px;
  }

  .menu-toggle {
    display: block;
    width: 40px;
    height: 40px;
    margin: 10px;
    float: right;
    cursor: pointer;
    text-align: center;
    font-size: 30px;
    background: var(--main-bg-color);
    color: var(--secondary-bg-color);

    &::before {
      line-height: 40px;
    }
  }
  nav {
    display: none;
  }
  header.active {
    nav {
      display: block;
      width: 100%;

      ul {
        display: block;

        li a {
          margin: 0;
        }
      }
    }
  }
}
